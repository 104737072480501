import React, { useState, useCallback } from 'react';
import LazyImage from '../../components/LazyImage';
import ImageActions from '../../components/ImageActions';
import Loader from '../../assets/icons/loader.svg'

const ImageGrid = ({ activeTab, images, setSelectedImage, fetchNextPage, hasNextPage, isFetchingNextPage }) => {

  const [savedImages, setSavedImages] = useState({});
  const [hoveredImageId, setHoveredImageId] = useState(null);

  const lastImageRef = useCallback(node => {
    if (node !== null) {
      const observer = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
          }
        },
        { threshold: 0.1 }
      );
      observer.observe(node);
      return () => observer.disconnect();
    }
  }, [hasNextPage, fetchNextPage]);

  const columns = [[], [], [], []];
  images.forEach((image, index) => {
    columns[index % 4].push(image);
  });

  return (
    <div className="px-4 py-8 w-full">
      <div className="flex flex-row gap-4 w-full">
        {columns.map((column, columnIndex) => (
          <div key={columnIndex} className="flex-1 flex flex-col gap-4">
            {column.map((image, index) => {
              const [aspectWidth, aspectHeight] = image.aspect_ratio.split(':').map(Number);
              const isLastImage = columnIndex === 3 && index === column.length - 1;

              return (
                <div
                  key={image.image_id}
                  ref={isLastImage ? lastImageRef : null}
                  className="relative w-full"
                  style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
                  onMouseEnter={() => setHoveredImageId(image.image_id)}
                  onMouseLeave={() => setHoveredImageId(null)}
                >
                  <LazyImage
                    src={activeTab === "upscales" ? image.upscale_url : image.image_url}
                    alt={`Generated image ${image.image_id}`}
                    className="w-full h-auto object-cover rounded-[20px] cursor-pointer"
                    onClick={() => {
                      setSelectedImage({
                        image: image,
                        prompt: image.prompt,
                        generationId: image.generation_id,
                      });
                    }}
                  />
                  {hoveredImageId === image.image_id && (
                    <div className="absolute bottom-0 right-0 flex w-full justify-end p-2">
                      <ImageActions
                        generationId={image.generation_id}
                        isSaved={savedImages[image.image_id]}
                        image={image}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ))}
      </div>
      {isFetchingNextPage && (
        <div className="w-full text-xl font-bold flex h-20 justify-center items-center mt-2 mb-10">
          <img className="h-20" src={Loader} alt="loader" />
        </div>
      )}
    </div>
  );

};

export default ImageGrid;
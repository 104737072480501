import React from 'react';
const CrossIcon = <svg width="35" height="35" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="27.5452" cy="27.4212" r="26.4543" fill="#F86262" stroke="white" stroke-width="1.72254" />
    <path d="M19.1777 19.3789L36.1161 36.3172" stroke="white" stroke-width="3.44509" />
    <path d="M36.1162 19.3789L19.1779 36.3172" stroke="white" stroke-width="3.44509" />
</svg>
const RightIcon = <svg width="35" height="35" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="28.3668" cy="28.1075" r="26.6469" fill="#6BCC63" stroke="white" stroke-width="1.73508" />
    <path d="M14.1548 28.6874L23.9869 38.5195L43.0728 19.4336" stroke="white" stroke-width="3.47016" />
</svg>


const DoAndDontSection = ({ dos, donts }) => {
    const renderItems = (items, icon) => (
        <div className="grid grid-cols-2 gap-4 ">
            {items.map((item, index) => (
                <div key={index} className="flex flex-col items-center">
                    <div className="relative w-full max-w-xs">
                        <div className="w-full aspect-1 rounded-3xl bg-gray-100">
                            <img
                                className="w-full h-auto rounded-3xl"
                                src={item.url}
                                alt={item.description}
                            />
                        </div>
                        {
                            item.description && <div className="absolute -top-3 -right-3 text-xl z-20">
                                {icon}
                            </div>
                        }

                    </div>
                    <p className="w-full text-start mt-2 text-sm">
                        {item.description}
                    </p>
                </div>
            ))}
        </div>
    );

    return (
        <div className="grid grid-cols-2 w-1/2 gap-4 my-6 mx-auto text-start ">
            <div className="space-y-4 ">
                {renderItems(donts, CrossIcon)}
            </div>
            <div className="space-y-4">
                {renderItems(dos, RightIcon)}
            </div>

        </div>
    );
};

export default DoAndDontSection;
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';

const monthlyPlans = [
  { id: 'P-17U451794R2419509M4HLDAQ', name: 'HOBBY', price: 12.00, credits: 900, icon: '🎨' },
  { id: 'P-7WA05698GW114015SM4IR4WI', name: 'CREATOR', price: 50.00, credits: 5000, icon: '🚀' },
  { id: 'P-9XY87654ZW3210987LMNOPQR', name: 'PRO', price: 240.00, credits: 25000, icon: '💼' },
];

function SubscriptionSuccessView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get('plan_id');

  const selectedPlan = monthlyPlans.find(plan => plan.id === planId);

  const handleGoToStudio = useCallback(async () => {
    if (selectedPlan) {
      try {
        const token = await getToken({template: 'token'});
        await axios.post('https://prod.foosh.ai/subscription-success', 
          { price: selectedPlan.price },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
        navigate('/studio');
      } catch (error) {
        console.error('Error updating credits:', error);
        // Handle error appropriately, e.g., show an error message to the user
        alert('An error occurred while processing your subscription. Please try again or contact support.');
      }
    } else {
      navigate('/studio');
    }
  }, [selectedPlan, navigate, getToken]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-4">Subscription Successful via PayPal!</h1>
        <p className="text-gray-700 mb-4">
          Thank you for subscribing. Your account has been successfully upgraded.
        </p>
        {selectedPlan && (
          <div className="text-gray-600 mb-6">
            <p className="font-semibold text-xl mb-2">{selectedPlan.icon} {selectedPlan.name} Plan</p>
            <p>Credits: <span className="font-semibold">{selectedPlan.credits.toLocaleString()}</span></p>
          </div>
        )}
        <button
          onClick={handleGoToStudio}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Go to Studio
        </button>
      </div>
    </div>
  );
}

export default SubscriptionSuccessView;
import React, { useState, useRef, useEffect } from 'react';

const ImageComparisonSlider = ({ leftImage, rightImage, aspectRatio }) => {
  const [sliderPosition, setSliderPosition] = useState(() => {
    if (leftImage) return 20;
    return 100;
  });
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);
  const [aspectWidth, aspectHeight] = aspectRatio?.split(':')?.map(Number);

  const handleMouseMove = e => {
    if (containerRef.current && leftImage && isDragging) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // Handle touch events
  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      handleMouseMove(touch);
    }
  };

  useEffect(() => {
    // Add global mouse/touch up listeners to handle cases where
    // the user releases outside the component
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchend', handleTouchEnd);
    
    // Add move listeners to the container
    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousemove', handleMouseMove);
      container.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleTouchEnd);
      if (container) {
        container.removeEventListener('mousemove', handleMouseMove);
        container.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isDragging]); // Re-run effect when isDragging changes

  return (
    <div
      ref={containerRef}
      className="relative w-full cursor-auto" // Changed cursor to auto by default
      style={{ aspectRatio: `${aspectWidth} / ${aspectHeight}` }}
    >
      {leftImage && (
        <img
          src={leftImage}
          alt="Left"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      )}
      {rightImage && (
        <img
          src={rightImage}
          alt="Right"
          className="absolute top-0 left-0 w-full h-full object-cover"
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        />
      )}
      {leftImage && (
        <div
          className="absolute top-0 bottom-0 w-0.5 bg-white"
          style={{ left: `${sliderPosition}%` }}
        >
          <div 
            className={`absolute top-1/2 left-1/2 w-16 h-16 -mt-4 -ml-8 rounded-full flex items-center justify-center ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
            onMouseDown={handleMouseDown}
            onTouchStart={handleTouchStart}
          >
            <img
              className="scale-150"
              src="https://framerusercontent.com/images/KtCypSLE0slt7XuqLDRdEzoGFiI.png"
              alt="crowler"
              draggable="false"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageComparisonSlider;
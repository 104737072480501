import { useEffect, useState } from 'react';

function useTypingAnimation(text, isActive, speed = 100) {
  const [typedText, setTypedText] = useState(text[0] || '');
  const [resetting, setResetting] = useState(false);

  useEffect(() => {
    let index = 1;
    let typingInterval;

    if (isActive && !resetting) {
      typingInterval = setInterval(() => {
        setTypedText(text[0] + text.slice(1, index + 1));
        index++;

        if (index >= text.length) {
          setResetting(true);
          index = 1;
          clearInterval(typingInterval);
        }
      }, speed);
    }


    if (resetting) {
      setTimeout(() => {
        setTypedText(text[0]);
        setResetting(false);
      }, 500);
    }

    return () => clearInterval(typingInterval);
  }, [text, isActive, speed, resetting]);

  return typedText;
}

export default useTypingAnimation;

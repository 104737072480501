// ModelImageUploadView.js
import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import Loader from '../../assets/icons/loader.svg'
import CropperSection from './CropperSection';
import ModelDescriptionSection from './ModelDescriptionSection';
import TrainingGuidelinesSection from './TrainingGuidelinesSection';
import { useAuth } from '@clerk/clerk-react';

const SubHeader = ({ subheaderText }) => {
    return (
        <div className="flex justify-between items-center py-2 px-6 border-b border-gray-200">
            <div className="flex">
                {/* Placeholder for logo or other left-aligned content */}
            </div>

            <div className="flex justify-center flex-grow p-2">{subheaderText}</div>
        </div>
    );
};

const ModelImageUploadView = () => {
    const inputRef = useRef(null);
    const [uploadedImages, setUploadedImages] = useState([]);
    const fileInputRef = useRef(null);
    const [productType, setProductType] = useState('product');
    const [modelIDR, setModelIDR] = useState(null);
    const [pageState, setPageState] = useState('guidelines')
    const [subheaderText, setSubheaderText] = useState('Train your AI model with perfect pictures for best results');
    const [modelData, setModelData] = useState({
        brandName: 'Brand name',
        productName: 'Product name',
        person_pet_name: "name",
        description: 'Here is the description ',
    });

    const { getToken } = useAuth();

    useEffect(() => {
        // Focus the input field when the component mounts
        if (inputRef.current) { inputRef.current.focus(); }
    });

    const handleImageUpload = e => {
        const files = Array.from(e.target.files).slice(0, 10); // Limit to 10 files
        const imageUrls = files.map(file => ({
            id: uuidv4(), // Assign a unique ID
            src: URL.createObjectURL(file),
        }));
        setUploadedImages(prevImages => [...prevImages, ...imageUrls]);
        setPageState("cropper");
        if (productType === "product") {
            setSubheaderText("Adjust the box so that your product is the only subject in the frame.");
        } else if (productType === "person"){
            setSubheaderText("Adjust the box to make sure that the person's face is within frame");
        } else if (productType === "pet") {
            setSubheaderText("Adjust the box to make sure that the pet's face is within frame");
        } else {
            setSubheaderText("Adjust the box so that your object is the only subject in the frame.");
        }
    };

    const uploadImage = async (uploadBody, modelId, token, index) => {
        try {
            const uploadResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/upload-training-image?model_id=${modelId}`,
                uploadBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            return uploadResponse.data.image_url;
        } catch (error) {
            console.error(`Error uploading image ${index}:`, error);
            throw error;
        }
    };

    const uploadImagesAfterCropping = async ({ compressedImagesBody }) => {
        try {
            const token = await getToken({ template: 'token' });
            // Step 1: Create the model

            setPageState("creating_model");
            const createModelResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/create-new-model`,
                { type: productType },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            const modelId = createModelResponse.data.model_id;
            setModelIDR(modelId);

            // Step 2: Compress, upload each image and collect URLs
            setPageState("uploading_images");
            const uploadPromises = compressedImagesBody.map((body, index) =>
                uploadImage(body, modelId, token, index)
            );

            const uploadedImageUrls = await Promise.all(uploadPromises);
            setPageState("show_model_description");
            setSubheaderText(`✅ ${uploadedImageUrls.length} photos uploaded and analysed!`)
            if (uploadedImageUrls.length === compressedImagesBody.length && productType === 'product') {
                setPageState("generating_model_description");
                try {
                    const descriptionResponse = await axios.get(
                        `${process.env.REACT_APP_API_URL}/create-model-description?model_id=${modelId}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setModelData({
                        brandName: descriptionResponse.data.brand,
                        productName: descriptionResponse.data.product_name,
                        description: descriptionResponse.data.description,
                    });
                } catch (error) {
                    console.error('Error fetching model description:', error);
                } finally {
                    setPageState("show_model_description")
                }
            }
            // Update state with uploaded image URLs
            setUploadedImages(uploadedImageUrls);
        } catch (error) {
            console.error('Error in handleFinishCropping:', error);
        }
    };

    if (pageState === "creating_model") {
        return (
            <div className="w-full  text-xl font-bold flex flex-col h-full justify-center items-center">
                <div>Creating model ...</div>
                <img className="h-20" src={Loader} alt="loader" />
            </div>
        );
    }

    if (pageState === "uploading_images") {
        return (
            <div className="w-full  text-xl font-bold flex flex-col h-full justify-center items-center">
                <div>Uploading Images ...</div>
                <img className="h-20" src={Loader} alt="loader" />
            </div>
        );
    }

    if (pageState === "generating_model_description") {
        return (
            <div className="w-full  text-xl font-bold flex flex-col h-full justify-center items-center">
                <div>Generating Model Description ...</div>
                <img className="h-20" src={Loader} alt="loader" />
            </div>
        )
    }

    return (
        <div className="w-full  h-full grow flex flex-col overflow-hidden overflow-x-clip  ">
            <div className="w-full   mx-auto grow  flex flex-col  overflow-y-scroll ">
                <SubHeader subheaderText={subheaderText} />
                {
                    pageState === "show_model_description"
                        ? <ModelDescriptionSection
                            modelId={modelIDR}
                            productType={productType}
                            modelData={modelData}
                            setModelData={setModelData}
                            uploadedImages={uploadedImages}
                            inputRef={inputRef}
                        />
                        : pageState === "cropper"
                            ? <CropperSection
                                uploadedImages={uploadedImages}
                                setUploadedImages={setUploadedImages}
                                fileInputRef={fileInputRef}
                                handleImageUpload={handleImageUpload}
                                uploadImagesAfterCropping={uploadImagesAfterCropping}
                            />
                            : <TrainingGuidelinesSection
                                productType={productType}
                                setProductType={setProductType}
                                fileInputRef={fileInputRef}
                                handleImageUpload={handleImageUpload}
                            />
                }
            </div>
        </div>
    );
};

export default ModelImageUploadView;

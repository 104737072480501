import { useState, useEffect, useCallback } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';

const useCredits = () => {
    const [credits, setCredits] = useState({ current: null, total: 0 });
    const { user } = useUser();
    const { getToken } = useAuth();

    const fetchCredits = useCallback(async () => {
        if (!user) return;

        console.log('Fetching credits for user ID:', user.id);
        try {
            const token = await getToken({template: 'token'});
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/credits`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch credits');
            }
            const data = await response.json();
            setCredits(data);
        } catch (error) {
            console.error('Error fetching credits:', error);
        }
    }, [user, getToken]);

    const updateCredits = async (amount) => {
        if (!user) return;

        console.log('Updating credits for user ID:', user.id);
        try {
            const token = await getToken({template: 'token'});
            const response = await fetch(`${process.env.REACT_APP_API_URL}/user/credits/update`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ amount }),
            });
            if (!response.ok) {
                throw new Error('Failed to update credits');
            }
            const data = await response.json();
            setCredits(data);
        } catch (error) {
            console.error('Error updating credits:', error);
            await fetchCredits(); // Refetch credits to ensure consistency
        }
    };

    useEffect(() => {
        fetchCredits();
    }, [fetchCredits]);

    return { credits, fetchCredits, updateCredits };
};

export default useCredits;
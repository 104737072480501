const SubHeader = ({ activeTab, setActiveTab }) => {
   const tabStyle = (tab) => `
     px-4 py-2 mx-2 rounded-md transition-colors duration-200
     ${activeTab === tab
       ? 'text-black font-semibold'
       : 'text-gray-500 hover:text-gray-700'
     }
   `;
 
   return (
     <div className="flex justify-between items-center py-2 px-6 border-b border-gray-200">
       <div className="w-1/3">
         {/* Placeholder for logo or other left-aligned content */}
       </div>
 
       <div className="w-1/3 flex justify-center">
         <button
           className={tabStyle('liked')}
           onClick={() => setActiveTab('liked')}
         >
           Liked shots
         </button>
         <button
           className={tabStyle('upscales')}
           onClick={() => setActiveTab('upscales')}
         >
           Upscales
         </button>
       </div>
       <div className="w-1/3 flex justify-end">
         <button
           className={`px-4 py-2 text-gray-500 hover:text-gray-700 transition-colors duration-200 ${activeTab === 'all' ? 'text-black font-semibold' : ''
             }`}
           onClick={() => setActiveTab('all')}
         >
           View all shots
         </button>
       </div>
     </div>
   );
 };
 export default SubHeader
import React from 'react';
import { AuthenticateWithRedirectCallback } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
  const navigate = useNavigate();

  const handleRedirectComplete = () => {
    navigate('/');
  };

  return (
    <AuthenticateWithRedirectCallback 
      afterSignInUrl="/"
      afterSignUpUrl="/"
      redirectUrl={window.location.href}
      onRedirectComplete={handleRedirectComplete}
    >
      <div>Processing authentication...</div>
    </AuthenticateWithRedirectCallback>
  );
};

export default OAuthCallback;
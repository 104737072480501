import React from 'react';
import { Link } from 'react-router-dom';

function SubscriptionFailureView() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center">
        <h1 className="text-3xl font-bold text-red-600 mb-4">Subscription Failed</h1>
        <p className="text-gray-700 mb-6">
          We're sorry, but there was an issue processing your subscription. Please try again or contact support if the problem persists.
        </p>
        <div className="space-x-4">
          <Link
            to="/payments"
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Try Again
          </Link>
          <Link
            to="/studio"
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Go to Studio
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionFailureView;
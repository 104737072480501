import React, { useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CreditCard, Zap, Mail, Phone } from 'lucide-react';

const IntlSub = () => {
  const [processingPlan, setProcessingPlan] = useState(null);
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingTopUp, setProcessingTopUp] = useState(false);
  const { getToken } = useAuth();
  const [showContactPopup, setShowContactPopup] = useState(false);

  const creditOptions = [
    { name: 'Adding a model', credits: 500, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 5, icon: <Zap size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <Zap size={24} /> },
  ];
  const monthlyPlans = [
    { id: 'P-17U451794R2419509M4HLDAQ', name: 'HOBBY', price: 12.00, credits: 900, icon: '🎨' },
    { id: 'P-7WA05698GW114015SM4IR4WI', name: 'CREATOR', price: 50.00, credits: 5000, icon: '🚀' },
    { id: 'P-9XY87654ZW3210987LMNOPQR', name: 'PRO', price: 240.00, credits: 25000, icon: '💼' },
  ];

  const handleContactUs = () => {
    setShowContactPopup(true);
  };

  const handleTopUpAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const handleTopUpAmountBlur = () => {
    let value = parseInt(topUpAmount);
    if (!isNaN(value) && value % 5 !== 0) {
      value = Math.ceil(value / 5) * 5;
      setTopUpAmount(value.toString());
    }
  };

  const calculateCredits = (amount) => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : Math.floor(parsedAmount / 5) * 500;
  };

  const handleTopUp = async () => {
    const amount = parseInt(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Please enter a valid amount (minimum $10)');
      return;
    }
    setProcessingTopUp(true);

    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-paypal-order`,
        { amount: amount },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Redirect to PayPal approval URL
      window.location.href = response.data.approval_url;
    } catch (error) {
      console.error('Error creating PayPal order:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      toast.error(`Error creating order: ${errorMessage}`);
    } finally {
      setProcessingTopUp(false);
    }
  };

  const handleSubscribe = async (plan) => {
    setProcessingPlan(plan.id);
  
    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-paypal-subscription`,
        { plan_id: plan.id },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.data && response.data.approval_url) {
        window.location.href = response.data.approval_url;
      } else {
        throw new Error('Approval URL not found in response');
      }
    } catch (error) {
      console.error('Error creating PayPal subscription:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      toast.error(`Error creating subscription: ${errorMessage}`);
    } finally {
      setProcessingPlan(null);
    }
  };
  const isTopUpButtonActive = parseInt(topUpAmount) >= 10;

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="grid grid-cols-3 gap-4 mb-8">
        {creditOptions.map((option, index) => (
          <div key={index} className="flex items-center">
            {option.icon}
            <div className="ml-2">
              <p>{option.name}</p>
              <p className="font-semibold">{option.credits} credits</p>
            </div>
          </div>
        ))}
      </div>

      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">One-time Top-up</h3>
        <div className="flex items-center">
          <input
            type="text"
            value={topUpAmount}
            onChange={handleTopUpAmountChange}
            onBlur={handleTopUpAmountBlur}
            className="w-24 p-2 border rounded mr-2"
            placeholder="Amount"
          />
          <span className="mr-4">{calculateCredits(topUpAmount)} credits</span>
          <div className="relative">
            <button 
              className={`px-4 py-2 rounded ${
                isTopUpButtonActive
                  ? 'bg-white text-black border border-black hover:bg-gray-100'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
              onClick={handleTopUp}
              disabled={processingTopUp || !isTopUpButtonActive}
            >
              {processingTopUp ? 'Processing...' : 'Top-up'}
            </button>
            {!isTopUpButtonActive && (
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-1 bg-gray-800 text-white text-xs rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                Minimum top-up is $10
              </div>
            )}
          </div>
        </div>
      </div>

      <h3 className="text-xl font-semibold mb-4">Monthly plans</h3>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {monthlyPlans.map((plan) => (
          <div key={plan.id} className="bg-gray-100 p-4 rounded">
            <h4 className="font-semibold">{plan.name} {plan.icon}</h4>
            <p>${plan.price} monthly</p>
            <p className="text-xl font-bold">{plan.credits} credits</p>
            <button 
              className="mt-2 bg-white text-black border border-black px-4 py-2 rounded w-full hover:bg-gray-100"
              onClick={() => handleSubscribe(plan)}
              disabled={processingPlan === plan.id}
            >
              {processingPlan === plan.id ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>

      <div className="bg-gray-100 p-4 rounded flex justify-between items-center">
        <div>
          <h4 className="font-bold text-lg mb-1">ENTERPRISE</h4>
          <p className="text-gray-600 text-sm">
            Unlock tailored pricing and unlimited shots for your teams.
          </p>
        </div>
        <button 
          className="bg-black text-white px-4 py-2 rounded text-sm font-semibold hover:bg-gray-800 transition-colors whitespace-nowrap ml-4"
          onClick={handleContactUs}
        >
          Contact us
        </button>
      </div>

      <p className="text-center text-sm mt-8">All plans get priority support on help@foosh.ai</p>

      {showContactPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <div className="flex items-center mb-3">
              <Mail className="mr-2" size={20} />
              <a href="mailto:help@foosh.ai" className="text-blue-600 hover:underline">help@foosh.ai</a>
            </div>
            <div className="flex items-center mb-4">
              <Phone className="mr-2" size={20} />
              <span>+1 (555) 123-4567</span>
            </div>
            <button 
              className="bg-black text-white px-4 py-2 rounded w-full hover:bg-gray-800"
              onClick={() => setShowContactPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IntlSub;
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import GenerationGroup from '../components/GenerationGroup';

const ShimmerEffect = () => (
    <div className="animate-pulse bg-gray-300 rounded-lg w-full h-64"></div>
);

const GeneratedImages = ({
    generatedGroups,
    loadMore,
    hasMore,
    setSelectedImage,
    showPrompt = false,
}) => {
    return (
        <InfiniteScroll
            dataLength={generatedGroups.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
                <div className="grid grid-cols-4 gap-4 mt-4">
                    {[...Array(4)].map((_, index) => (
                        <ShimmerEffect key={index} />
                    ))}
                </div>
            }
        >
            {
                generatedGroups.map((group, index) =>
                    <GenerationGroup
                        showPrompt={showPrompt}
                        aspectRatio={group.aspect_ratio}
                        generationId={group.generation_id}
                        images={group.images}
                        prompt={group.prompt}
                        setSelectedImage={setSelectedImage}
                    />
                )
            }
        </InfiniteScroll>
    );
};

export default GeneratedImages;
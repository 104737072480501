import { useAuth } from '@clerk/clerk-react';
import { Navigate } from 'react-router-dom';
import Loader from '../assets/icons/loader.svg'

const ProtectedRoute = ({ children }) => {
  const { isSignedIn, isLoaded } = useAuth();

  if (!isLoaded) {
    return  <div className="w-full text-xl font-bold flex h-full justify-center items-center">
    <img className="h-20" src={Loader} alt="loader" />
  </div>;
  }

  if (!isSignedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};

export default ProtectedRoute;
const nuxdata = {
  "Leena": {
    model: {
      name: "Leena",
      image:
        "https://d2h4y8xaq7mn9l.cloudfront.net/leena-3.png"
    },
    prompt: "A hyper-realistic photograph of Leena with a bright smile, seated confidently in the driver's seat of a sleek, modern car. Her expression is warm and cheerful, with a genuine smile that lights up her face as she glances slightly toward the camera, capturing a moment of pure joy.",
    images: [

      "https://d2h4y8xaq7mn9l.cloudfront.net/leena-1.png",
      "https://d2h4y8xaq7mn9l.cloudfront.net/leena-2.png"
    ]
  },
  "Grey Foosh chair": {
    model: {
      name: "Grey Foosh chair",
      image:
        "https://d2h4y8xaq7mn9l.cloudfront.net/chair-3.png"
    },
    prompt: "A lifestyle photo of the Grey Foosh chair. The chair is placed in a cozy living room setting with soft, natural lighting coming from a large window on the side, creating a warm and inviting ambiance. The grey fabric texture of the chair should be clearly visible, with subtle shadows enhancing its contours. A person is casually seated on the chair, reading a book or sipping coffee, showing the chair's comfort and practicality.",
    images: [
      "https://d2h4y8xaq7mn9l.cloudfront.net/chair-1.png",
      "https://d2h4y8xaq7mn9l.cloudfront.net/chair-2.png"
    ]
  },
  "Fooshie": {
    model: {
      name: "Fooshie",
      image:
        "https://d2h4y8xaq7mn9l.cloudfront.net/fooshie-3.png"
    },
    prompt: "A hyper-realistic photograph of Fooshie confidently seated in the cockpit of an F22 Raptor fighter jet. Fooshie is wearing sleek, reflective aviator sunglasses, adding a cool, commanding presence to the scene. His paws rest on the jet's high-tech controls, as he appears completely in control, gazing out through the cockpit windshield at a clear blue sky. The interior of the cockpit is intricately detailed, with a mix of digital screens, buttons, and levers, all illuminated by soft blue and green glows from the control panels.",
    images: [
      "https://d2h4y8xaq7mn9l.cloudfront.net/fooshie-1.png",
      "https://d2h4y8xaq7mn9l.cloudfront.net/fooshie-2.png"
    ]
  },
  "Walkr Shoes": {
    model: {
      name: "Walkr Shoes",
      image: "/walkrshoes.png"
    },
    prompt: "Anime comic art style close-up of Walkr sports shoes by Souled Store, presented with bold, dynamic lines and dramatic shading typical of manga panels. The shoes are captured from a low-angle view, emphasizing their sleek, futuristic design with exaggerated perspective. Sharp cel-shading enhances the details—textured mesh, bold accents, and reflective highlights—while action lines radiate from the shoes to amplify the sense of motion.",
    images: [
      "https://d2h4y8xaq7mn9l.cloudfront.net/walkr-1.png",
      "https://d2h4y8xaq7mn9l.cloudfront.net/walkr-3.png"
    ]
  }
};

export default nuxdata;
import React, { useState } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CreditCard, Zap, Mail, Phone } from 'lucide-react';

const PaymentView = () => {
  const [topUpProcessing, setTopUpProcessing] = useState(false);
  const [processingPlans, setProcessingPlans] = useState({});
  const [topUpAmount, setTopUpAmount] = useState('');
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const [showContactPopup, setShowContactPopup] = useState(false);

  const razorpayKey = "rzp_live_VB3jVhl6kBcJed";

  const creditOptions = [
    { name: 'Adding a model', credits: 500, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 5, icon: <Zap size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <Zap size={24} /> },
  ];

  const monthlyPlans = [
    { id: 'plan_P7Mi0zuDU6Mhmg', name: 'HOBBY', price: 1000, credits: 1000, icon: '🎨' },
    { id: 'plan_P7MkMGfLEyZpXY', name: 'CREATOR', price: 4200, credits: 4200, icon: '🚀' },
    { id: 'plan_P7Mn9vWBFMcVZP', name: 'PRO', price: 20100, credits: 20100, icon: '💼' },
  ];

  const handleTopUpAmountChange = (e) => {
    const amount = e.target.value;
    setTopUpAmount(amount);
  };

  const calculateCredits = (amount) => {
    return Math.floor(parseFloat(amount) * 100);
  };

  const handleTopUp = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is ₹10');
      return;
    }
    await handlePayment('oneTime', calculateCredits(amount), amount);
  };

  const handleContactUs = () => {
    setShowContactPopup(true);
  };

  const handleSubscribe = async (plan) => {
    await handlePayment('subscription', plan.credits, plan.price, plan.id);
  };

  const handlePayment = async (paymentType, credits, amount, planId = null) => {
    if (paymentType === 'oneTime') {
      setTopUpProcessing(true);
    } else {
      setProcessingPlans(prev => ({ ...prev, [planId]: true }));
    }

    try {
      if (!razorpayKey) {
        throw new Error('Razorpay Key ID is not set');
      }

      const token = await getToken({template: 'token'});
      let response;
      if (paymentType === 'oneTime') {
        const amountInPaise = Math.round(amount * 100);
        console.log('Sending one-time payment amount:', amountInPaise);
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-razorpay-order`,
          { amount: amountInPaise },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } else {
        console.log('Sending subscription request for plan:', planId);
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create-razorpay-subscription`,
          { plan_id: planId },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      }

      console.log('Received response:', response.data);

      const options = {
        key: razorpayKey,
        amount: response.data.amount,
        currency: response.data.currency,
        name: "FOOSH.ai",
        description: paymentType === 'oneTime' ? `Top up ${credits} credits` : `Subscribe to ${planId}`,
        order_id: response.data.id,
        subscription_id: paymentType === 'subscription' ? response.data.subscription_id : undefined,
        handler: async function (razorpayResponse) {
          try {
            console.log('Razorpay response:', razorpayResponse);
            const verifyToken = await getToken();
            const paymentData = {
              razorpay_payment_id: razorpayResponse.razorpay_payment_id,
              razorpay_signature: razorpayResponse.razorpay_signature,
              razorpay_order_id: razorpayResponse.razorpay_order_id,
              razorpay_subscription_id: razorpayResponse.razorpay_subscription_id,
              payment_type: paymentType
            };
            console.log('Payment verification data:', paymentData);
            
            const verifyResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/verify-razorpay-payment`,
              paymentData,
              {
                headers: {
                  'Authorization': `Bearer ${verifyToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            
            console.log('Verification response:', verifyResponse.data);
            
            if (verifyResponse.data.success) {
              if ((paymentType === 'oneTime' && verifyResponse.data.status === 'captured') || 
                  (paymentType === 'subscription' && verifyResponse.data.status === 'active')) {
                navigate(`/payment-success?amount=${amount}&type=${paymentType}&credits=${credits}`);
              } else {
                navigate(`/payment-pending?amount=${amount}&type=${paymentType}&status=${verifyResponse.data.status}`);
              }
            } else {
              throw new Error(verifyResponse.data.message || 'Payment verification failed');
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            const errorMessage = error.response?.data?.detail || error.response?.data?.message || error.message;
            console.error('Detailed error:', errorMessage);
            console.error('Error response:', error.response?.data);
            navigate(`/payment-failure?amount=${amount}&type=${paymentType}&reason=${encodeURIComponent(errorMessage)}`);
          } finally {
            if (paymentType === 'oneTime') {
              setTopUpProcessing(false);
            } else {
              setProcessingPlans(prev => ({ ...prev, [planId]: false }));
            }
          }
        },
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
        },
        modal: {
          ondismiss: function() {
            if (paymentType === 'oneTime') {
              setTopUpProcessing(false);
            } else {
              setProcessingPlans(prev => ({ ...prev, [planId]: false }));
            }
          }
        }
      };

      console.log('Razorpay options:', options);

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.detail || error.message;
        console.error('Detailed error:', errorMessage);
        toast.error(`Error creating payment order: ${errorMessage}`);
      } else {
        toast.error('Error creating payment order. Please try again.');
      }
      if (paymentType === 'oneTime') {
        setTopUpProcessing(false);
      } else {
        setProcessingPlans(prev => ({ ...prev, [planId]: false }));
      }
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <h3 className="text-lg font-semibold mr-4">Top up more credits</h3>
          <span className="text-2xl font-bold">₹</span>
          <input
            type="number"
            value={topUpAmount}
            onChange={handleTopUpAmountChange}
            className="w-24 text-2xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-black"
            min="10"
            step="1"
            placeholder="10"
          />
        </div>
        <button 
          className={`px-4 py-2 rounded ${
            parseFloat(topUpAmount) >= 10 ? 'bg-black text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'
          }`}
          onClick={handleTopUp}
          disabled={topUpProcessing || parseFloat(topUpAmount) < 10}
        >
          {topUpProcessing ? 'Processing...' : `Add ${calculateCredits(topUpAmount) || 0} credits`}
        </button>
      </div>

      <div className="grid grid-cols-3 gap-4 mb-8">
        {creditOptions.map((option, index) => (
          <div key={index} className="flex items-center">
            {option.icon}
            <div className="ml-2">
              <p>{option.name}</p>
              <p className="font-semibold">{option.credits} credits</p>
            </div>
          </div>
        ))}
      </div>

      <h3 className="text-xl font-semibold mb-4">Monthly plans</h3>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {monthlyPlans.map((plan) => (
          <div key={plan.id} className="bg-gray-100 p-4 rounded">
            <h4 className="font-semibold">{plan.name} {plan.icon}</h4>
            <p>₹{plan.price} monthly</p>
            <p className="text-xl font-bold">{plan.credits} credits</p>
            <button 
              className="mt-2 bg-white text-black border border-black px-4 py-2 rounded w-full"
              onClick={() => handleSubscribe(plan)}
              disabled={processingPlans[plan.id]}
            >
              {processingPlans[plan.id] ? 'Processing...' : 'Subscribe'}
            </button>
          </div>
        ))}
      </div>

      <div className="bg-gray-100 p-4 rounded flex justify-between items-center">
        <div>
          <h4 className="font-bold text-lg mb-1">ENTERPRISE</h4>
          <p className="text-gray-600 text-sm">
            Unlock tailored pricing and unlimited shots for your teams.
          </p>
        </div>
        <button 
          className="bg-black text-white px-4 py-2 rounded text-sm font-semibold hover:bg-gray-800 transition-colors whitespace-nowrap ml-4"
          onClick={handleContactUs}
        >
          Contact us
        </button>
      </div>

      <p className="text-center text-sm mt-8">All plans get priority support on help@foosh.ai</p>

      {showContactPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-semibold mb-4">Contact Us</h3>
            <div className="flex items-center mb-3">
              <Mail className="mr-2" size={20} />
              <a href="mailto:help@foosh.ai" className="text-blue-600 hover:underline">help@foosh.ai</a>
            </div>
            <div className="flex items-center mb-4">
              <Phone className="mr-2" size={20} />
              <span>+1 (555) 123-4567</span>
            </div>
            <button 
              className="bg-black text-white px-4 py-2 rounded w-full"
              onClick={() => setShowContactPopup(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentView;
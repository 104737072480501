import React from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useUser } from '@clerk/clerk-react';


export default function IntercomComponent() {
  const { user } = useUser();

  if (user) {
    Intercom({
      app_id: 'ybxsg8jx',
      user_id: user.id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
      name: user.firstName, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
      email: user.primaryEmailAddress.emailAddress, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
      created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }

  return <div></div>;
}
import React, { useState } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CreditCard, Zap } from 'lucide-react';

const UnifiedOneTimePayments = () => {
  const [topUpAmount, setTopUpAmount] = useState('');
  const [processingRazorpay, setProcessingRazorpay] = useState(false);
  const [processingPaypal, setProcessingPaypal] = useState(false);
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const razorpayKey = "rzp_live_VB3jVhl6kBcJed";

  const creditOptions = [
    { name: 'Adding a model', credits: 800, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 7, icon: <Zap size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <Zap size={24} /> },
  ];

  const handleTopUpAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setTopUpAmount(value);
    }
  };

  const calculateCredits = (amount) => {
    const parsedAmount = parseInt(amount);
    return isNaN(parsedAmount) ? 0 : parsedAmount * 100;
  };

  const handleRazorpayPayment = async () => {
    const amount = parseFloat(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Minimum top-up amount is $10');
      return;
    }
    await processRazorpayPayment(calculateCredits(amount), amount);
  };

  const handlePaypalPayment = async () => {
    const amount = parseInt(topUpAmount);
    if (isNaN(amount) || amount < 10) {
      toast.error('Please enter a valid amount (minimum $10)');
      return;
    }
    setProcessingPaypal(true);

    try {
      const token = await getToken({template: 'token'});
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-paypal-order`,
        { amount: amount },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Redirect to PayPal approval URL
      window.location.href = response.data.approval_url;
    } catch (error) {
      console.error('Error creating PayPal order:', error);
      const errorMessage = error.response?.data?.detail || error.message;
      toast.error(`Error creating order: ${errorMessage}`);
    } finally {
      setProcessingPaypal(false);
    }
  };

  const processRazorpayPayment = async (credits, amount) => {
    setProcessingRazorpay(true);
    try {
      if (!razorpayKey) {
        throw new Error('Razorpay Key ID is not set');
      }

      const token = await getToken({template: 'token'});
      const amountInPaise = Math.round(amount * 100 * 84.5);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-razorpay-order`,
        { amount: amountInPaise },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const options = {
        key: razorpayKey,
        amount: response.data.amount,
        currency: response.data.currency,
        name: "FOOSH.ai",
        description: `Top up ${credits} credits`,
        order_id: response.data.id,
        handler: async function (razorpayResponse) {
          try {
            const verifyToken = await getToken();
            const paymentData = {
              razorpay_payment_id: razorpayResponse.razorpay_payment_id,
              razorpay_signature: razorpayResponse.razorpay_signature,
              razorpay_order_id: razorpayResponse.razorpay_order_id,
              payment_type: 'oneTime'
            };
            
            const verifyResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/verify-razorpay-payment`,
              paymentData,
              {
                headers: {
                  'Authorization': `Bearer ${verifyToken}`,
                  'Content-Type': 'application/json',
                },
              }
            );
            
            if (verifyResponse.data.success && verifyResponse.data.status === 'captured') {
              navigate(`/payment-success?amount=${amount}&type=oneTime&credits=${credits}`);
            } else {
              navigate(`/payment-pending?amount=${amount}&type=oneTime&status=${verifyResponse.data.status}`);
            }
          } catch (error) {
            console.error('Error verifying payment:', error);
            const errorMessage = error.response?.data?.detail || error.message;
            navigate(`/payment-failure?amount=${amount}&type=oneTime&reason=${encodeURIComponent(errorMessage)}`);
          } finally {
            setProcessingRazorpay(false);
          }
        },
        prefill: {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
        },
        modal: {
          ondismiss: function() {
            setProcessingRazorpay(false);
          }
        }
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error('Error creating Razorpay order:', error);
      toast.error('Error creating payment order. Please try again.');
      setProcessingRazorpay(false);
    }
  };


  const isPaypalButtonActive = parseInt(topUpAmount) >= 10;

  const handleContactUs = () => {
    // Replace this URL with your actual Calendly link
    const calendlyUrl = "https://calendly.com/hi-foosh/30min";
    window.open(calendlyUrl, "_blank");
  };


  return (
    <div className="max-w-6xl mx-auto p-4">
      <style jsx>{`
        /* Remove arrow keys from number input */
        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button { 
          -webkit-appearance: none; 
          margin: 0; 
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
      `}</style>
      <h2 className="text-2xl font-bold mb-4">Upgrade more credits to your Foosh account</h2>
      
      <div className="mb-8">
        <div className="flex items-start mb-4">
          <div className="mr-12 flex-grow">
            <div className="flex items-center mb-2">
              <span className="text-2xl font-bold mr-2">$</span>
              <input
                type="text"
                value={topUpAmount}
                onChange={handleTopUpAmountChange}
                className="w-32 text-2xl font-bold border-b-2 border-gray-300 focus:outline-none focus:border-black"
                placeholder="10"
              />
            </div>
            <div className="h-16 flex items-end">
              <p className={`text-xl font-semibold ${parseInt(topUpAmount) < 10 ? 'text-red-500' : 'text-gray-600'}`}>
                {parseInt(topUpAmount) < 10 
                  ? 'Minimum top-up is $10'
                  : `${calculateCredits(topUpAmount)} credits`}
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-4 flex-grow">
            <button 
              onClick={handleRazorpayPayment}
              disabled={processingRazorpay || processingPaypal || parseInt(topUpAmount) < 10}
              className={`px-6 py-3 rounded text-lg font-semibold w-full ${
                parseInt(topUpAmount) >= 10 && !processingRazorpay && !processingPaypal
                  ? 'bg-black text-white hover:bg-gray-800'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingRazorpay ? 'Processing...' : 'Pay from India with Razorpay'}
            </button>
            <button 
              onClick={handlePaypalPayment}
              disabled={processingPaypal || !isPaypalButtonActive}
              className={`px-6 py-3 rounded text-lg font-semibold w-full ${
                isPaypalButtonActive && !processingPaypal
                  ? 'bg-blue-500 text-white hover:bg-blue-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              {processingPaypal ? 'Processing...' : 'Pay from anywhere with PayPal'}
            </button>
          </div>
        </div>
      </div>

      <p className="text-lg font-bold underline text-black-600 mb-2">
        How it works:
      </p>
      <div className="grid grid-cols-3 gap-4 mb-8">
        {creditOptions.map((option, index) => (
          <div key={index} className="flex items-center">
            {option.icon}
            <div className="ml-2">
              <p>{option.name}</p>
              <p className="font-semibold">{option.credits} credits</p>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-gray-100 p-4 rounded flex justify-between items-center mb-8">
        <div>
          <h4 className="font-bold text-lg mb-1">ENTERPRISE</h4>
          <p className="text-gray-600 text-sm">
            Unlock tailored pricing and unlimited shots for your teams.
          </p>
        </div>
        <button 
          className="bg-black text-white px-4 py-2 rounded text-sm font-semibold hover:bg-gray-800 transition-colors whitespace-nowrap ml-4"
          onClick={handleContactUs}
        >
          Contact us
        </button>
      </div>

      <p className="text-sm text-gray-600">
        Prices are subject to change anytime.
      </p>
      <p className="text-sm text-gray-600"> Get priority support @ support@foosh.ai</p>
    </div>
  );
};

export default UnifiedOneTimePayments;
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { CreditCard, Camera, ZoomIn } from 'lucide-react';
import axios from 'axios';

const PaymentSuccessViewPaypal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();

  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get('amount');
  const type = searchParams.get('type');
  const credits = searchParams.get('credits');

  const creditOptions = [
    { name: 'Adding a model', credits: 500, icon: <CreditCard size={24} /> },
    { name: 'Create a shot', credits: 5, icon: <Camera size={24} /> },
    { name: 'Upscale a shot', credits: 30, icon: <ZoomIn size={24} /> },
  ];

  useEffect(() => {
    const processPayment = async () => {
      try {
        const token = await getToken({template: 'token'});
        if (!token) return;

        const parsedAmount = parseFloat(amount);
        if (isNaN(parsedAmount)) return;

        await axios.post('https://prod.foosh.ai/payment-success-paypal', 
          { amount: parsedAmount },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (error) {
        // Silently log error to console for debugging
        console.error('Error processing payment:', error);
      }
    };

    processPayment();
  }, [amount, getToken]);

  const handleContinue = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="p-8 bg-white rounded-lg shadow-md w-full max-w-md text-center">
        <h1 className="text-3xl font-bold mb-4 text-green-600">
          🐠 Just keep Fooshin'! 🐠
        </h1>
        
        <p className="text-xl font-semibold mb-6">
          Payment Successful!
        </p>

        <p className="mb-4">
          Your {type === 'oneTime' ? 'one-time payment' : 'subscription'} of ${amount} has been processed successfully via Paypal.
        </p>
        
        <p className="text-2xl font-semibold mb-6">
          {credits} credits added.
        </p>
        
        <div className="grid grid-cols-3 gap-4 mb-6">
          {creditOptions.map((option, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded">
              <div className="text-3xl mb-2">{option.icon}</div>
              <p className="font-semibold">{option.name}</p>
              <p className="text-lg font-bold">{option.credits} credits</p>
            </div>
          ))}
        </div>
        
        <p className="text-sm italic mb-6">
          PS: Your credits never expire!
        </p>
        
        <button 
          className="w-full bg-black text-white py-3 rounded-lg mb-4"
          onClick={handleContinue}
        >
          Continue making epic shots
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccessViewPaypal;
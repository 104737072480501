// components/CropperSection.js
import React, { useState } from 'react';
import ImageCropper from './ImageCropper';
import { ImagePlus } from 'lucide-react';
import Loader from '../../assets/icons/loader.svg'

const CropperSection = ({
    uploadedImages,
    setUploadedImages,
    handleImageUpload,
    fileInputRef,
    uploadImagesAfterCropping
}) => {
    const [croppedImages, setCroppedImages] = useState([]);

    // Add the delete function to your parent component
    const handleDeleteImage = id => {
        setUploadedImages(prevImages =>
            prevImages.filter(image => image.id !== id)
        );
        setCroppedImages(prevCroppedImages =>
            prevCroppedImages.filter(image => image.id !== id)
        );
    };

    const compressImage = (file, maxWidth, maxHeight, quality) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = event => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    let width = img.width;
                    let height = img.height;

                    if (width > height) {
                        if (width > maxWidth) {
                            height *= maxWidth / width;
                            width = maxWidth;
                        }
                    } else {
                        if (height > maxHeight) {
                            width *= maxHeight / height;
                            height = maxHeight;
                        }
                    }

                    canvas.width = width;
                    canvas.height = height;

                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob(
                        blob => {
                            resolve(blob);
                        },
                        'image/jpeg',
                        quality
                    );
                };
            };
        });
    };

    const convertToBase64 = blob => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result;
                resolve(base64String);
            };
            reader.onerror = error => reject(error);
            reader.readAsDataURL(blob);
        });
    };

    const compressedImageBodyForUpload = async (image, index) => {
        const compressedBlob = await compressImage(image, 4096, 4096, 0.95);
        const base64 = await convertToBase64(compressedBlob);

        const body = {
            blob: base64.split(',')[1],
            file_extension: '.jpg',
        };
        return body;
    }

    const handleFinishCropping = async () => {
        const compressPromises = croppedImages.map((image, index) =>
            compressedImageBodyForUpload(image.src, index)
        );

        const compressedImages = await Promise.all(compressPromises);
        uploadImagesAfterCropping({ compressedImagesBody: compressedImages });
    };

    return (
        <div className="w-full h-full flex grow flex-col">
            {uploadedImages.length !== croppedImages.length && (
                <div className="w-full text-xl font-bold flex flex-col h-20 justify-center items-center mt-[30vh] mb-[100vh]">
                    <p>Processing your images...</p>
                    <img className="h-20" src={Loader} alt="loader" />
                </div>
            )}
            <div className="py-8 mx-10 grid grid-cols-4 gap-8 pb-20">
                {uploadedImages.map(image => (
                    <div key={image.id} className="bg-gray-100 rounded-md col-span-1">
                        <ImageCropper
                            src={image.src}
                            onCropComplete={croppedImageUrl =>
                                setCroppedImages(prev => {
                                    const index = prev.findIndex(img => img.id === image.id);
                                    if (index !== -1) {
                                        const newImages = [...prev];
                                        newImages[index] = { id: image.id, src: croppedImageUrl };
                                        return newImages;
                                    }
                                    return [...prev, { id: image.id, src: croppedImageUrl }];
                                })
                            }
                            onDelete={() => handleDeleteImage(image.id)}
                        />
                    </div>
                ))}
            </div>
            {uploadedImages.length === croppedImages.length && (
                <div className="flex absolute bottom-0 w-full justify-center gap-2 p-2">
                    <div className="flex cursor-pointer">
                        <label
                            htmlFor="file-upload"
                            className="border-gray-400 border-[1px] bg-white cursor-pointer py-2 px-4 rounded-3xl"
                        >
                            <div className="flex w-full h-full gap-2">
                                <div className="h-full aspect-1">
                                    <ImagePlus className="w-full h-full" />
                                </div>
                                <div>Add more photos</div>
                            </div>
                        </label>
                        <input
                            id="file-upload"
                            type="file"
                            ref={fileInputRef}
                            multiple
                            accept="image/png, image/jpeg"
                            className="hidden"
                            onChange={handleImageUpload}
                        />
                    </div>
                    <button
                        onClick={handleFinishCropping}
                        disabled={uploadedImages.length !== croppedImages.length}
                        className={`${uploadedImages.length !== croppedImages.length ? "bg-gray-200" : "bg-black"
                            } text-white font-sans font-[600] py-2 px-16 rounded-3xl`}
                    >
                        {'Proceed >'}
                    </button>
                </div>
            )}
        </div>
    );
};

export default CropperSection;